import React from "react";
import { Row } from "react-bootstrap";
import { Desc, Heading } from "../../Global/Typography";
import GrassPattern from "../GrassPattern";

import PrevArrow from "../../../images/Common/PrevArrow.svg";
import NextArrow from "../../../images/Common/NextArrow.svg";
import "./ArchitecturalSlider.scss";

import SwiperCore, {
    Navigation,
    Pagination,
    Scrollbar,
    A11y,
    Controller,
    Autoplay,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Controller, Autoplay]);

const view1 = "/images/IvyCounty/architectural/architecture-1.jpg";
const view2 = "/images/IvyCounty/architectural/architecture-2.png";
const view3 = "/images/IvyCounty/architectural/architecture-3.jpg";
const view4 = "/images/IvyCounty/architectural/architecture-4.jpg";
const view5 = "/images/IvyCounty/architectural/architecture-5.jpg";
export default function ArchitecturalSlider({ inView }) {
    const ArchitecturalSliderData = [
        {
            title: "An Ideal Residential Corridor",
            desc: "Home to top-tier schools and seamless connectivity to workplaces in ORR, Whitefield, and Sarjapur Road, this area boasts exceptional infrastructure development, making it an ideal location to build your dream home.",
            image: view1,
            imgAlt: "An Ideal Residential Corridor",
        },

        {
            title: "A High-Growth Appreciation Hub",
            desc: "Significant infrastructure developments, including the interconnection of IRR, PRR, STRR, and CDP roads, make this area an ideal choice for property investment with high potential for value appreciation.",
            image: view2,
            imgAlt: "A High-Growth Appreciation Hub",
        },
        {
            title: "Home Customization Freedom",
            desc: "This premium residential plots, offer the unique advantage of creating a custom-designed villa, tailored to your lifestyle and preferences, within a well-planned plotted community.",
            image: view3,
            imgAlt: "Home Customization Freedom",
        },
        {
            title: "Serene Environment",
            desc: "Ivy County offers the tranquility of an emerging area, providing a peaceful and spacious environment, ideal for family living.",
            image: view4,
            imgAlt: "Serene Environment",
        },
        {
            title: "Exclusive Community Living",
            desc: "Our plotted villas are thoughtfully integrated into a master-planned community, providing enhanced security, premium amenities such as clubhouses, and a vibrant sense of belonging.",
            image: view5,
            imgAlt: "Exclusive Community Living",
        },
    ];

    return (
        <section
            className={`section bg-white ArchitecturalSlider ${
                inView ? "inView" : "outView"
            }
    `}
        >
            <GrassPattern />
            <Row className="ArchitecturalSlider__Row">
                <Swiper
                    wrapperTag="ul"
                    className="ArchitecturalSliderLists"
                    slidesPerView={1}
                    speed={500}
                    // speed={3400}
                    spaceBetween={0}
                    effect={"fade"}
                    fadeEffect={{
                        crossFade: true,
                    }}
                    // observeParents={true}
                    // observer={true}

                    autoplay={true}
                    loop={true}
                    direction="horizontal"
                    pagination={{
                        clickable: true,
                        el: ".swiper-pagination-Architech",
                    }}
                    navigation={{
                        prevEl: ".ArrowButtonBlock__ArrowPrevArchitech",
                        nextEl: ".ArrowButtonBlock__ArrowNextArchitech",
                    }}
                >
                    {ArchitecturalSliderData.map(
                        ({ image, title, desc, imgAlt }, index) => (
                            <SwiperSlide
                                tag="li"
                                key={index}
                                className="-block-list"
                            >
                                <div className="-image-block">
                                    <img
                                        src={image}
                                        className="-image "
                                        style={{
                                            aspectRatio: "9/5",
                                        }}
                                        alt={imgAlt}
                                    />
                                </div>
                                <div className="-architech-co-block">
                                    <div className="-architech-co-block__inner soulace">
                                        <Heading
                                            variant="h1"
                                            classList="-title"
                                            text={title}
                                        />
                                        <Desc
                                            classList="-desc my-3 "
                                            text={desc}
                                        />
                                    </div>
                                </div>
                            </SwiperSlide>
                        )
                    )}
                </Swiper>
            </Row>
            <Row className="ArrowButtons">
                <div className="ArrowButtonBlock">
                    <div className="swiper-pagination swiper-pagination-Architech"></div>
                    <div className="ArrowButtonBlock__Arrow ArrowButtonBlock__ArrowPrevArchitech">
                        <img
                            src={PrevArrow}
                            className="-image"
                            alt="ArchitecturalSlider"
                        />
                    </div>
                    <div className="ArrowButtonBlock__Arrow ArrowButtonBlock__ArrowNextArchitech">
                        <img
                            src={NextArrow}
                            className="-image"
                            alt="ArchitecturalSlider"
                        />
                    </div>
                </div>
            </Row>
        </section>
    );
}
