export const ongoingProjects = [
    {
        label: "Ivy County",
        link: "/ivy-county",
        // type: "newLaunch",
        reraApproved: true,
        reraNumber: "ACK/KA/RERA/1251/308/PR/291024/008626",
    },
    {
        label: "Serene Heights",
        link: "/serene-heights",
    },
    {
        label: "Soulace",
        link: "/soulace",
    },
    {
        label: "Engrace Vista",
        link: "/engrace-vista",
    },
];

export const upcomingProjects = [
    {
        label: "Central Park",
        link: "/central-park",
        type: "newLaunch",
    },
    {
        label: "Onyx",
        link: "/onyx",
        type: "newLaunch",
    },
    {
        label: "Green Storeys",
        link: "/green-storeys",
        type: "newLaunch",
    },
    {
        label: "Windmere",
        link: "/windmere",
        type: "newLaunch",
    },
    {
        label: "Walk Town Residences",
        link: "/walk-town-residences",
        type: "upcoming",
    },
    {
        label: "Tranquil Terraces",
        link: "/tranquil-terraces",
        type: "upcoming",
    },

    {
        label: "Fo’Rest of ur lyf",
        link: "/for'rest-of-ur-lyf",
        type: "upcoming",
    },
    // {
    //   label: "Stellar Springs",
    //   link: "/stellar-springs",
    //   type: "upcoming",
    // },
];

export const completedProjects = [
    {
        label: "Engrace",
        link: "/engrace",
        soldOut: true,
    },
    {
        label: "Soul Tree",
        link: "/soultree",
        soldOut: true,
    },
    {
        label: "Urbanest",
        link: "/urbanest",
        soldOut: true,
    },
];
