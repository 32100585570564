import { useState } from "react";
import { InView } from "react-intersection-observer";
import {
    ArchitecturalSlider,
    Banner,
    Campus,
    // Clubs,
    // ClubConnect,
    Develop,
    ExcellentConnectivity,
    GalleryTabs,
    OutdoorSeating,
    TheHeartAndSoul,
    ProjectAtGlance,
    Schools,
    SmartGreenMinimal,
    SocialEntertainment,
    // OtherProject,
    Specifications,
    TranquilCourt,
} from "../components/IvyCounty";
// import { Banner, ContactUs } from "../components/SoulTree";
import { Helmet } from "react-helmet-async";
import { Footer, FooterLogo } from "../components/index";
import ProjectResources from "../components/ProjectResources";

import { ContactUs } from "../components/SoulTree";
import { Blog } from "../components/Urbanest";
import { ProjectResourcesData } from "../components/IvyCounty/ProjectResources/constants";

export default function IvyCounty() {
    const [inViewBanner, setInViewBanner] = useState(false);
    const [inViewArchitecturalSlider, setInViewArchitecturalSlider] =
        useState(false);
    const [inViewProjectAtGlance, setInViewProjectAtGlance] = useState(false);
    // const [inViewMasterPlan, setInViewMasterPlan] = useState(false);
    const [inViewProjectResources, setInViewProjectResources] = useState(false);

    // const [inViewBlocks, setInViewBlocks] = useState(false);

    const [inViewTheHeartAndSoul, setInViewTheHeartAndSoul] = useState(false);
    // const [inViewClubs, setInViewClubs] = useState(false);
    const [inViewClubConnect, setInViewClubConnect] = useState(false);
    const [inViewTranquilCourt, setInViewTranquilCourt] = useState(false);
    const [inViewOutdoorSeating, setInViewOutdoorSeating] = useState(false);
    // const [inViewAmenities, setInViewAmenities] = useState(false);
    const [inViewSmartGreenMinimal, setInViewSmartGreenMinimal] =
        useState(false);
    const [inViewExcellentConnectivity, setInViewExcellentConnectivity] =
        useState(false);
    const [inViewCampus, setInViewCampus] = useState(false);
    const [inViewSchools, setInViewSchools] = useState(false);
    const [inViewSocialEntertainment, setInViewSocialEntertainment] =
        useState(false);
    const [inViewDevelop, setInViewDevelop] = useState(false);
    const [inViewGalleryTabs, setInViewGalleryTabs] = useState(false);
    const [inViewSpecifications, setInViewSpecifications] = useState(false);
    // const [inViewOtherProject, setInViewOtherProject] = useState(false);
    const [inViewContactUs, setInViewContactUs] = useState(false);
    const [inViewBlog, setInViewBlog] = useState(false);

    const [inViewFooter, setInViewFooter] = useState(false);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0"
                />
                <title>Boutique 2 & 3 BHK Apartments | Modern Spaaces</title>
                <meta
                    name="description"
                    content="Green Storeys by Modern Spaaces offer unique 2 & 3 BHK homes with 80% Open Space
"
                />
            </Helmet>
            <InView
                className="bgPattern"
                onChange={setInViewBanner}
                triggerOnce={true}
            >
                <Banner inView={inViewBanner}></Banner>
            </InView>

            <InView
                className="bgPattern"
                onChange={setInViewArchitecturalSlider}
                triggerOnce={true}
            >
                <ArchitecturalSlider
                    inView={inViewArchitecturalSlider}
                ></ArchitecturalSlider>
            </InView>

            {/* <InView onChange={setInViewContactUs} triggerOnce={true}></InView> */}
            <InView
                className="bgPattern "
                onChange={setInViewProjectAtGlance}
                triggerOnce={true}
            >
                <ProjectAtGlance
                    inView={inViewProjectAtGlance}
                ></ProjectAtGlance>
            </InView>

            <InView
                className="bgPattern"
                onChange={setInViewProjectResources}
                triggerOnce={true}
            >
                <ProjectResources
                    inView={inViewProjectResources}
                    resources={ProjectResourcesData}
                    project="Ivy County"
                ></ProjectResources>
            </InView>

            <InView
                className="bgPattern"
                onChange={setInViewTheHeartAndSoul}
                triggerOnce={true}
            >
                <TheHeartAndSoul
                    inView={inViewTheHeartAndSoul}
                ></TheHeartAndSoul>
            </InView>

            <InView
                className="bgPattern mt-md-2 pt-md-1"
                onChange={setInViewTranquilCourt}
                triggerOnce={true}
            >
                <TranquilCourt inView={inViewTranquilCourt}></TranquilCourt>
            </InView>

            {/* <InView
                className="bgPattern mdOverflowHidden"
                id="club-trigger"
                onChange={setInViewClubConnect}
                triggerOnce={true}
            >
                <ClubConnect inView={inViewClubConnect}></ClubConnect>
            </InView> */}

            {/* <InView
        className="bgPattern mdOverflowHidden"
        onChange={setInViewClubs}
        triggerOnce={true}
      >
        <Clubs inView={inViewClubs}></Clubs>
      </InView> */}

            {/* <InView
                className="bgPattern"
                onChange={setInViewOutdoorSeating}
                triggerOnce={true}
            >
                <OutdoorSeating inView={inViewOutdoorSeating}></OutdoorSeating>
            </InView>

            <InView
                className="bgPattern"
                onChange={setInViewSmartGreenMinimal}
                triggerOnce={true}
            >
                <SmartGreenMinimal
                    inView={inViewSmartGreenMinimal}
                ></SmartGreenMinimal>
            </InView> */}

            <InView
                className="bgPattern "
                onChange={setInViewExcellentConnectivity}
                triggerOnce={true}
            >
                <ExcellentConnectivity
                    inView={inViewExcellentConnectivity}
                ></ExcellentConnectivity>
            </InView>

            <InView
                className="bgPattern "
                onChange={setInViewCampus}
                triggerOnce={true}
            >
                <Campus inView={inViewCampus}></Campus>
            </InView>

            <InView
                className="bgPattern "
                onChange={setInViewSchools}
                triggerOnce={true}
            >
                <Schools inView={inViewSchools}></Schools>
            </InView>

            <InView
                className="bgPattern "
                onChange={setInViewSocialEntertainment}
                triggerOnce={true}
            >
                <SocialEntertainment
                    inView={inViewSocialEntertainment}
                ></SocialEntertainment>
            </InView>

            <InView
                className="bgPattern "
                onChange={setInViewDevelop}
                triggerOnce={true}
            >
                <Develop inView={inViewDevelop}></Develop>
            </InView>
            <InView
                className="bgPattern "
                onChange={setInViewBlog}
                triggerOnce={true}
            >
                <Blog inView={inViewBlog}></Blog>
            </InView>
            <InView
                className="bgPattern bgPattern--black bgPattern--leftSquareFull"
                onChange={setInViewGalleryTabs}
                triggerOnce={true}
            >
                <GalleryTabs inView={inViewGalleryTabs}></GalleryTabs>
            </InView>

            {/* <InView
                className="bgPattern bgPattern--black"
                onChange={setInViewSpecifications}
                triggerOnce={true}
            >
                <Specifications inView={inViewSpecifications}></Specifications>
            </InView> */}

            {/* <InView
        className="bgPattern "
        onChange={setInViewOtherProject}
        triggerOnce={true}
      >
        <OtherProject inView={inViewOtherProject}></OtherProject>
      </InView> */}

            <InView
                className="bgPattern bgPattern--HrGrassPattern"
                onChange={setInViewContactUs}
                triggerOnce={true}
            >
                <ContactUs inView={inViewContactUs}></ContactUs>
            </InView>
            <FooterLogo />
            <InView
                className="bgPattern "
                onChange={setInViewFooter}
                triggerOnce={true}
            >
                <Footer inView={inViewFooter}></Footer>
            </InView>
        </>
    );
}

// // const Onyx = () => {
// //   return <></>;
// // };

// // export default Onyx;

// import { useState } from "react";
// import { InView } from "react-intersection-observer";

// import { ContactUs } from "../components/Engrace";
// import { resources as downloadResourcesList } from "../components/Onyx/DownloadResources/constants";

// import { amenities } from "../components/Onyx/Amenities/constants";
// import {
//     AmenitiesSection,
//     Blog,
//     DownloadResources,
//     GalleryTabs,
//     MasterPlan,
//     ProjectAtAGlance,
//     Specifications,
// } from "../components/ProjectDetail";
// import Banner from "../components/IvyCounty/Banner";
// import { GalleryTabsItems } from "../components/IvyCounty/GalleryTabs/constants";
// import { masterPlanData } from "../components/IvyCounty/MasterPlan/constants";
// import { projectAtAGlanceListItems } from "../components/IvyCounty/ProjectAtAGlance/constants";
// import { SpecificationsItems } from "../components/IvyCounty/Specifications/constants";
// import { Footer, FooterLogo } from "../components/index";
// import Blogs from "../home/blogs/blogs";
// import { Helmet } from "react-helmet-async";

// export default function IvyCounty() {
//     const [inViewBanner, setInViewBanner] = useState(false);
//     const [inViewArchitecturalSlider, setInViewArchitecturalSlider] =
//         useState(false);
//     const [inViewProjectAtGlance, setInViewProjectAtGlance] = useState(false);
//     const [inViewMasterPlan, setInViewMasterPlan] = useState(false);
//     const [inViewDownloadResources, setInViewDownloadResources] =
//         useState(false);
//     const [inViewAmenities, setInViewAmenities] = useState(false);
//     // const [inViewBlocks, setInViewBlocks] = useState(false);

//     const [inViewTheHeartAndSoul, setInViewTheHeartAndSoul] = useState(false);
//     // const [inViewClubs, setInViewClubs] = useState(false);
//     // const [inViewClubConnect, setInViewClubConnect] = useState(false);
//     const [inViewTranquilCourt, setInViewTranquilCourt] = useState(false);
//     const [inViewOutdoorSeating, setInViewOutdoorSeating] = useState(false);
//     // const [inViewAmenities, setInViewAmenities] = useState(false);
//     // const [inViewSmartGreenMinimal, setInViewSmartGreenMinimal] = useState(false);
//     const [inViewExcellentConnectivity, setInViewExcellentConnectivity] =
//         useState(false);
//     const [inViewCampus, setInViewCampus] = useState(false);
//     const [inViewSchools, setInViewSchools] = useState(false);
//     const [inViewSocialEntertainment, setInViewSocialEntertainment] =
//         useState(false);
//     const [inViewDevelop, setInViewDevelop] = useState(false);
//     const [inViewGalleryTabs, setInViewGalleryTabs] = useState(false);
//     const [inViewSpecifications, setInViewSpecifications] = useState(false);
//     // const [inViewOtherProject, setInViewOtherProject] = useState(false);
//     const [inViewContactUs, setInViewContactUs] = useState(false);
//     const [inViewBlog, setInViewBlog] = useState(false);
//     const [inViewBlogs, setInViewBlogs] = useState(false);

//     const [inViewFooter, setInViewFooter] = useState(false);

//     return (
//         <>
//             <Helmet>
//                 <meta charSet="utf-8" />
//                 <meta
//                     name="viewport"
//                     content="width=device-width, initial-scale=1.0"
//                 />
//                 <title>
//                     1.5,2 and 3 BHK Flats for sale in Sompura Gate, Sarjapura |
//                     Modern Spaaces
//                 </title>
//                 <meta
//                     name="description"
//                     content="Ivy County by Modern Spaaces offers 1.5, 2, and 3 BHK in Sarjapur road, experience contemporary living in our modern high-rise apartment with dual clubhouse.
// "
//                 />
//             </Helmet>
//             <InView
//                 // className="bgPattern"
//                 onChange={setInViewBanner}
//                 triggerOnce={true}
//             >
//                 <Banner inView={inViewBanner}></Banner>
//             </InView>

//             {/* <InView
//                 className="bgPattern"
//                 onChange={setInViewArchitecturalSlider}
//                 triggerOnce={true}
//             >
//                 <ArchitecturalSlider
//                     inView={inViewArchitecturalSlider}
//                 ></ArchitecturalSlider>
//             </InView> */}

//             <InView onChange={setInViewContactUs} triggerOnce={true}></InView>
//             <InView
//                 className="bgPattern "
//                 onChange={setInViewProjectAtGlance}
//                 triggerOnce={true}
//             >
//                 <ProjectAtAGlance
//                     inView={inViewProjectAtGlance}
//                     projectAtAGlanceItems={projectAtAGlanceListItems}
//                 ></ProjectAtAGlance>
//             </InView>

//             <InView
//                 className="bgPattern "
//                 onChange={setInViewMasterPlan}
//                 triggerOnce={true}
//             >
//                 <MasterPlan
//                     inView={inViewMasterPlan}
//                     masterPlanData={masterPlanData}
//                 ></MasterPlan>
//             </InView>
//             <InView
//                 onChange={setInViewDownloadResources}
//                 triggerOnce={true}
//                 className="bgPattern"
//             >
//                 <DownloadResources
//                     inView={inViewDownloadResources}
//                     resources={downloadResourcesList}
//                 ></DownloadResources>
//             </InView>
//             <InView
//                 onChange={setInViewAmenities}
//                 triggerOnce={true}
//                 className="bgPattern"
//             >
//                 <AmenitiesSection
//                     inView={inViewAmenities}
//                     amenities={amenities}
//                 ></AmenitiesSection>
//             </InView>
//             <InView
//                 className="bgPattern bgPattern--HrGrassPattern "
//                 onChange={setInViewBlog}
//                 triggerOnce={true}
//             >
//                 <Blog inView={inViewBlog}></Blog>
//             </InView>
//             {/* <InView
//         className="bgPattern"
//         onChange={setInViewBlocks}
//         triggerOnce={true}
//       >
//         <Blocks inView={inViewBlocks}></Blocks>
//       </InView> */}

//             {/* <InView
//         className="bgPattern"
//         onChange={setInViewTheHeartAndSoul}
//         triggerOnce={true}
//       >
//         <TheHeartAndSoul inView={inViewTheHeartAndSoul}></TheHeartAndSoul>
//       </InView> */}

//             {/* <InView
//         className="bgPattern mdOverflowHidden"
//         id="club-trigger"
//         onChange={setInViewClubConnect}
//         triggerOnce={true}
//       >
//         <ClubConnect inView={inViewClubConnect}></ClubConnect>
//       </InView> */}

//             {/* <InView
//         className="bgPattern mdOverflowHidden"
//         onChange={setInViewClubs}
//         triggerOnce={true}
//       >
//         <Clubs inView={inViewClubs}></Clubs>
//       </InView> */}

//             {/* <InView
//         className="bgPattern"
//         onChange={setInViewOutdoorSeating}
//         triggerOnce={true}
//       >
//         <OutdoorSeating inView={inViewOutdoorSeating}></OutdoorSeating>
//       </InView> */}

//             {/* <InView
//         className="bgPattern mt-md-2 pt-md-1"
//         onChange={setInViewTranquilCourt}
//         triggerOnce={true}
//       >
//         <TranquilCourt inView={inViewTranquilCourt}></TranquilCourt>
//       </InView> */}

//             {/* <InView
//         className="bgPattern"
//         onChange={setInViewAmenities}
//         triggerOnce={true}
//       >
//         <Amenities inView={inViewAmenities}></Amenities>
//       </InView> */}

//             {/* <InView
//         className="bgPattern"
//         onChange={setInViewSmartGreenMinimal}
//         triggerOnce={true}
//       >
//         <SmartGreenMinimal inView={inViewSmartGreenMinimal}></SmartGreenMinimal>
//       </InView> */}

//             {/* <InView
//         className="bgPattern bgPattern--bottom"
//         onChange={setInViewExcellentConnectivity}
//         triggerOnce={true}
//       >
//         <ExcellentConnectivity
//           inView={inViewExcellentConnectivity}
//         ></ExcellentConnectivity>
//       </InView>

//       <InView
//         className="bgPattern bgPattern--rightSquare"
//         onChange={setInViewCampus}
//         triggerOnce={true}
//       >
//         <Campus inView={inViewCampus}></Campus>
//       </InView>

//       <InView
//         className="bgPattern bgPattern--leftSquare"
//         onChange={setInViewSchools}
//         triggerOnce={true}
//       >
//         <Schools inView={inViewSchools}></Schools>
//       </InView>

//       <InView
//         className="bgPattern bgPattern--leftSquare"
//         onChange={setInViewSocialEntertainment}
//         triggerOnce={true}
//       >
//         <SocialEntertainment
//           inView={inViewSocialEntertainment}
//         ></SocialEntertainment>
//       </InView>

//       <InView
//         className="bgPattern bgPattern--rightSquare"
//         onChange={setInViewDevelop}
//         triggerOnce={true}
//       >
//         <Develop inView={inViewDevelop}></Develop>
//       </InView> */}

//             <InView
//                 className="bgPattern bgPattern--black bgPattern--leftSquareFull"
//                 onChange={setInViewGalleryTabs}
//                 triggerOnce={true}
//             >
//                 <GalleryTabs
//                     inView={inViewGalleryTabs}
//                     galleryTabsItems={GalleryTabsItems}
//                 ></GalleryTabs>
//             </InView>

//             <InView
//                 className="bgPattern bgPattern--black"
//                 onChange={setInViewSpecifications}
//                 triggerOnce={true}
//             >
//                 <Specifications
//                     inView={inViewSpecifications}
//                     specificationsItems={SpecificationsItems}
//                 ></Specifications>
//             </InView>

//             {/* <InView
//         className="bgPattern "
//         onChange={setInViewOtherProject}
//         triggerOnce={true}
//       >
//         <OtherProject inView={inViewOtherProject}></OtherProject>
//       </InView> */}
//             <InView onChange={setInViewBlogs} triggerOnce={true}>
//                 <Blogs inView={inViewBlogs}></Blogs>
//             </InView>
//             <InView
//                 className="bgPattern "
//                 onChange={setInViewContactUs}
//                 triggerOnce={true}
//             >
//                 <ContactUs inView={inViewContactUs}></ContactUs>
//             </InView>
//             <FooterLogo />

//             <InView
//                 className="bgPattern "
//                 onChange={setInViewFooter}
//                 triggerOnce={true}
//             >
//                 <Footer inView={inViewFooter}></Footer>
//             </InView>
//         </>
//     );
// }
