import React, { Component } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const Gallery1 = "/images/IvyCounty/gallery/gallery-1.png";
const Gallery3 = "/images/IvyCounty/gallery/gallery-2.jpg";
const Gallery7 = "/images/IvyCounty/gallery/gallery-3.jpg";
const Gallery8 = "/images/IvyCounty/gallery/gallery-4.jpg";
const Gallery2 = "/images/IvyCounty/gallery/gallery-5.png";
const Gallery4 = "/images/IvyCounty/gallery/gallery-6.jpeg";
const Gallery5 = "/images/IvyCounty/gallery/gallery-7.jpg";
const Gallery6 = "/images/IvyCounty/gallery/gallery-8.jpg";
const Gallery9 = "/images/IvyCounty/gallery/gallery-9.jpg";

const images = [
    Gallery1,
    Gallery2,
    Gallery3,
    Gallery4,
    Gallery5,
    Gallery6,
    Gallery7,
    Gallery8,
    Gallery9,
];

export default class GSlider extends Component {
    constructor(props) {
        super(props);

        this.state = {
            photoIndex: 0,
            isOpen: false,
        };
    }

    render() {
        const { photoIndex, isOpen } = this.state;

        return (
            <div className="GSliderHoverEle">
                <img
                    // title="Click to View More Images"
                    className="LightHouseImage"
                    src={Gallery1}
                    alt="LightHouseImage"
                    onClick={() => this.setState({ isOpen: true })}
                />

                <div
                    className="videoIconImage videoIconImage--text"
                    onClick={() => this.setState({ isOpen: true })}
                >
                    View
                </div>
                {/* <div className="divBl">Click to View More Images</div> */}
                {isOpen && (
                    <Lightbox
                        mainSrc={images[photoIndex]}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={
                            images[
                                (photoIndex + images.length - 1) % images.length
                            ]
                        }
                        // imageTitle={imagesCaption[photoIndex]}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex:
                                    (photoIndex + images.length - 1) %
                                    images.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % images.length,
                            })
                        }
                    />
                )}
            </div>
        );
    }
}
