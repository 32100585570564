import { Col, Container, Row } from "react-bootstrap";
// AnchorButtonIcon
import { Desc, Heading } from "../../Global/Typography";
// import CampusImage from "../../../images/SoulTree/Campus/CampusImage.png";
import "./Campus.scss";

const CampusImage = "/images/Soulace/Campus/daily-commute-to-work-banner.png";

export default function Campus({ inView }) {
    const CampusData = [
        {
            title: "How far is my work?",
            desc: (
                <>
                    Seamless connectivity to workplaces in{" "}
                    <b>ORR, Whitefield, and Sarjapur Road</b>, this area boasts
                    exceptional infrastructure development, making it an ideal
                    location to build your dream home.
                </>
            ),
            // button: "View on map",
            image: CampusImage,
            listItems: [
                <>
                    RGA Tech Park <strong>9.5 Kms</strong>
                </>,
                <>
                    Wipro Corporate Park <strong>7.5 Kms</strong>
                </>,
                <>
                    Electronic City IT Hub <strong>15 Kms</strong>
                </>,
                <>
                    Whitefield IT Hub <strong>13 Kms</strong>
                </>,
                <>
                    Cessna Business Park <strong>11 Kms</strong>,
                </>,
                <>
                    RMZ Ecospace <strong>13 Kms</strong>
                </>,
            ],
        },
    ];

    return (
        <section
            className={`section ${inView ? "inView" : "outView"}
    `}
        >
            {CampusData.map((item, idx) => {
                return (
                    <Container key={idx} className="Campus">
                        <Row className="align-items-center flex-column-reverse flex-md-row">
                            <Col
                                xs={12}
                                md={6}
                                lg={6}
                                xl={7}
                                className="px-md-5"
                            >
                                <Heading
                                    variant="h1"
                                    classList="Campus__title"
                                    text={item.title}
                                />
                                <Desc
                                    classList="Campus__desc my-3 "
                                    text={item.desc}
                                />
                                <ul className="TranquilCourtList">
                                    {item.listItems.map((i, j) => (
                                        <li key={j}>
                                            <Desc classList="my-1" text={i} />
                                        </li>
                                    ))}
                                </ul>
                                {/* <AnchorButtonIcon
                  // RECORD AUDIO BASED CHANGE
                  target="_blank"
                  href="https://www.google.com/maps/place/Soul+Tree+by+Modern+Spaaces/@12.86056,77.7643898,17z/data=!3m1!4b1!4m5!3m4!1s0x3bae738c023db8b3:0x1c0e1c2d5d103375!8m2!3d12.8605548!4d77.7665785"
                  // RECORD AUDIO BASED CHANGE
                  classList="Campus__btn my-3"
                  text={item.button}
                  icon={true}
                /> */}
                            </Col>
                            <Col
                                xs={12}
                                md={6}
                                lg={6}
                                xl={5}
                                className="pr-md-5"
                            >
                                <div className="imagePatch">
                                    <img
                                        src={item.image}
                                        className="Campus__image w-100 mb-4 mb-md-5"
                                        alt="Daily commute to Work"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                );
            })}
        </section>
    );
}
