// import floorPlan from "../../../images/Common/floor-plan.svg";
// import masterPlan from "../../../images/Common/master-plan.svg";
// import projectBrochure from "../../../images/Common/project-brochure.svg";

import EngraceMasterPlan from "../../../documents/Engrace/Engrace_Masterplan.pdf";
import EngraceBrochure from "../../../documents/Engrace/Engrace_Brochure.pdf";
import EngraceFloorPlan from "../../../documents/Engrace/Engrace_4_Floor_Plans_SF.pdf";


const floorPlan = "/images/Common/floor-plan-banner.svg";
const masterPlan = "/images/Common/master-plan-banner.svg";
const projectBrochure = "/images/Common/project-brochure-banner.svg";

export const ProjectResources = [
    {
        key: "floorPlan",
        name: "Floor Plan",
        icon: floorPlan,
        IconAlt:"Floor Plan",
        resourceContent: {
            subject: "Engrace Floor Plan",
            fileName: "Engrace - Floor Plan.pdf",
            filePath: EngraceFloorPlan,
        },
    },
    {
        key: "masterPlan",
        name: "Master Plan",
        icon: masterPlan,
        IconAlt:"Master Plan",
        resourceContent: {
            subject: "Engrace Master Plan",
            fileName: "Engrace - Master Plan.pdf",
            filePath: EngraceMasterPlan,
        },
    },
    {
        key: "projectBrochure",
        name: "Project Brochure",
        icon: projectBrochure,
        IconAlt:"Project Brochure",
        resourceContent: {
            subject: "Engrace Brochure",
            fileName: "Engrace - Brochure.pdf",
            filePath: EngraceBrochure,
        },
    },
];
