import ImgB4 from "../../images/Blogs/ImgB4.png";

import ImgTopReasonsSarjapura from "../../images/Blogs/TopReasonsSarjapura.jpeg";

const ImgMivanTechnology = "/images/Blogs/mivan-technology-banner.jpeg";
const ImgEnergyEfficientBuilding =
    "/images/Blogs/energy-efficient-building-banner.png";
const ImgAdvOfVillas = "/images/Blogs/advantages-of-luxury-villas-banner.png";
const ImgFactorsImpactingRELocation =
    "/images/Blogs/impact-on-real-estate-location-banner.png";
const ImgExclusive3BHKCommunity =
    "/images/Blogs/benefits-of-living-in-an-exclusive-3-BHK-community.png";

const budget2024 = "/images/Blogs/budget-2024.jpg";
const readyMadeOrCustomImage = "/images/Blogs/ready-made-or-custom-villa.png";
const apartmentVsHouse = "/images/Blogs/BlogApartmentVsHouse.png";
const engraceVistaTrue3BHK = "/images/Blogs/BlogEngraceVistaTrue3BHK.png";
const BlogPerfectHome = "/images/Blogs/perfect-home-1.jpg";
const BlogPetOwnership = "/images/Blogs/pet-ownership-1.png";
const BlogNatureAtHome = "/images/Blogs/nature-at-home-banner.webp";
const BlogMovingOutMadeEasy = "/images/Blogs/blog-moving-out-made-easy-1.png";

export const FeaturedBlogs = [
    {
        id: "moving-out-made-easy",
        slug: "moving-out-made-easy",
        image: BlogMovingOutMadeEasy,
        imgAlt: "Moving Out Made Easy: Tips and Tricks to Pack Like a Pro",
        label: "Real Estate",
        desc: "Learn how to organise, protect, and relocate your belongings like a pro with these expert tips and tricks explained in the blog.",
        title: "Moving Out Made Easy: Tips and Tricks to Pack Like a Pro",
        date: "December,2024",
        time: "7 min read",
        imageStyle: {
            objectFit: "cover",
            objectPosition: "right",
        },
    },
    {
        id: "nature-at-home",
        slug: "nature-at-home",
        image: BlogNatureAtHome,
        imgAlt: "Nature at Home: Balcony Garden Ideas for Urban Living",
        label: "Real Estate",
        desc: "Transform your urban balcony into a serene garden oasis with our inspiring decor ideas! Discover practical tips to create your perfect balcony retreat in this blog.",
        title: "Nature at Home: Balcony Garden Ideas for Urban Living",
        date: "November,2024",
        time: "7 min read",
        imageStyle: {
            objectFit: "cover",
            objectPosition: "left",
        },
    },
    {
        id: "a-comprehensive-guide-for-pet-ownership-in-bangalore",
        slug: "a-comprehensive-guide-for-pet-ownership-in-bangalore",
        image: BlogPetOwnership,
        imgAlt: "A Comprehensive Guide for Pet Ownership in Bangalore",
        label: "Real Estate",
        desc: "This blog delves into the significance of investing in a pet-friendly home and how Sarjapur stands out as a pet-friendly neighbourhood.",
        title: "A Comprehensive Guide for Pet Ownership in Bangalore",
        date: "November,2024",
        time: "7 min read",
        imageStyle: {
            objectFit: "cover",
            objectPosition: "center",
        },
    },
    {
        id: "why-pre-launch-properties-are-tickets-to-your-perfect-home",
        slug: "why-pre-launch-properties-are-tickets-to-your-perfect-home",
        image: BlogPerfectHome,
        imgAlt: "Why Pre-Launch Properties Are Ticket to Your Perfect Home?",
        label: "Real Estate",
        desc: "Explore the benefits of buying pre-launch properties: lower prices, customisation options, and potential high returns. Learn to navigate risks and make informed investments.",
        title: "Why Pre-Launch Properties Are Ticket to Your Perfect Home?",
        date: "November,2024",
        time: "7 min read",
        imageStyle: {
            objectFit: "cover",
            objectPosition: "center",
        },
    },
    {
        id: "buying-apartment-vs-building-house",
        slug: "buying-apartment-vs-building-house",
        image: apartmentVsHouse,
        imgAlt: "Are you still over the fence between Buying an Apartment and Building a House?",
        label: "Real Estate",
        desc: "Explore the key differences between building an independent home and buying an apartment. This guide covers cost, time, customisation, and resale value to help you choose the best path to your dream home.",
        title: "Are you still over the fence between Buying an Apartment and Building a House?",
        date: "October,2024",
        time: "7 min read",
        imageStyle: {
            objectFit: "cover",
            objectPosition: "left",
        },
    },
    {
        id: "why-is-engrace-vista-the-true-3-bhk",
        slug: "why-is-engrace-vista-the-true-3-bhk",
        image: engraceVistaTrue3BHK,
        imgAlt: "Why is Engrace Vista the True 3 BHK?",
        label: "Real Estate",
        desc: "Discover Engrace Vista: Homes with spacious layouts and modern amenities designed to redefine your lifestyle.",
        title: "Why is Engrace Vista the True 3 BHK?",
        date: "October,2024",
        time: "4 min read",
    },
    {
        id: "readyMadeOrCustom",
        slug: "ready-made-villa-vs-custom-build",
        image: readyMadeOrCustomImage,
        imgAlt: "Ready-Made Villa or Custom Build: What’s Your Perfect Home Match?",
        label: "Real Estate",
        desc: "Discover the pros and cons of buying a villa versus building a standalone home. Explore factors like luxury, privacy, customisation, and budget to determine the best option for your lifestyle.",
        title: "Ready-Made Villa or Custom Build: What’s Your Perfect Home Match?",
        date: "September,2024",
        time: "7 min read",
        imageStyle: {
            objectFit: "cover",
            objectPosition: "left",
        },
    },
    {
        id: "budget2024",
        slug: "budget-2024",
        image: budget2024,
        imgAlt: "Budget 2024 - Your Wallet's New BFF or Your Worst Enemy",
        label: "Real Estate",
        desc: "Discover how Budget 2024 reshapes India's real estate landscape. Understand the potential implications for property buyers, sellers, and investors.",
        title: "Budget 2024 : Your Wallet's New BFF or Your Worst Enemy",
        date: "September,2024",
        time: "7 min read",
    },
    {
        id: "abc100",
        slug: "benefits-of-living-in-an-exclusive-3-BHK-community",
        title: "Benefits of living in an exclusive 3 BHK community",
        imgAlt: "Exclusive 3 BHK community",
        image: ImgExclusive3BHKCommunity,
        desc: "Discover Engrace Vista, an exclusive 3 BHK community off Sarjapur Road, Bangalore. Experience the best of urban living with spacious apartments, top-tier amenities, enhanced security, and a vibrant community atmosphere",
        date: "May,2024",
        time: "8 min read",
    },
    {
        id: "abc101",
        slug: "exploring-the-mivan-technology",
        // image: "https://modernspaaces.com/BlogListImages/BlogFour/Banner.webp",
        image: ImgMivanTechnology,
        imgAlt: "Mivan Technology - Aluform Technology",
        label: "REAL ESTATE",
        desc: "Exploring the technology of Mivan and its impact on construction. Learn more about the technology through a detailed analysis.",
        title: "Revolutionizing Construction: Exploring the Mivan Technology",
        date: "January,2024",
        time: "5 min read",
    },
    {
        id: "abc102",
        slug: "choose-between-green-traditional-energy-efficient-buildings",
        // image: "https://modernspaaces.com/BlogListImages/BlogFour/Banner.webp",
        image: ImgEnergyEfficientBuilding,
        imgAlt: "Energy Efficent Buildings",
        label: "REAL ESTATE",
        desc: "Urbanization and the Changing Landscape The global wave of urbanization has profoundly reshaped landscapes, impacting natural resources and intensifying carbon emissions.",
        title: "Choosing Between Green, Traditional, and Energy-Efficient Buildings in Real Estate",
        date: "January,2024",
        time: "5 min read",
    },
    // {
    //     id: "abc103",
    //     slug: "why-sarjapura-best-area-to-buy-house",
    //     // image: "https://modernspaaces.com/BlogListImages/BlogFour/Banner.webp",
    //     image: ImgTopReasonsSarjapura,
    //     label: "REAL ESTATE",
    //     desc: "For any real estate project, its amenities are the most crucial part, as that’s what the builders use to lure potential home buyers For any real estate project, its amenities are the most crucial part, as that’s what the builders use to lure…",
    //     title: "Top reasons why Sarjapur Road is the best area for buying a home",
    //     date: "Dec, 2023",
    //     time: "3 min read",
    // },
    {
        id: "abc104",
        slug: "advantage-of-villas-exploring-luxury-living",
        // image: "https://modernspaaces.com/BlogListImages/BlogFour/Banner.webp",
        image: ImgAdvOfVillas,
        imgAlt: "Advantages of Luxury Living Villas",
        label: "REAL ESTATE",
        desc: "Are you feeling overwhelmed by all the choices for your new home? Unsure whether you should construct your own home or buy a ready-to-move-in Villa? Are you confused between an apartment and a villa? We have got you covered in this blogs...",
        title: "Prime Advantage of Villas: Exploring Luxury Living",
        date: "January,2024",
        time: "3 min read",
    },
    {
        id: "abc104",
        slug: "factors-impacting-real-estate-location",
        // image: "https://modernspaaces.com/BlogListImages/BlogFour/Banner.webp",
        image: ImgFactorsImpactingRELocation,
        imgAlt: "Factors Impacting Real Estate Location",
        label: "REAL ESTATE",
        desc: "Explore the vital role of location in real estate investments. Learn how factors like amenities, economic trends, and growth potential shape a property's value.",
        title: "Factors Impacting Real Estate Location",
        date: "January,2024",
        time: "3 min read",
    },
];
