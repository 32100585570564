import { Col, Container, Row } from "react-bootstrap";
// AnchorButtonIcon
import { Desc, Heading } from "../../Global/Typography";
// import CampusImage from "../../../images/SoulTree/Campus/CampusImage.png";
import "./SocialEntertainment.scss";

const CampusImage = "/images/SereneHeights/SocialEntertainment/Shopping.png";

export default function SocialEntertainment({ inView }) {
    const SocialEntertainmentData = [
        {
            title: "What social, entertainment, and shopping options are available?",
            desc: (
                <>
                    Being situated in a <b>rapidly growing</b> part of
                    Bangalore, the area is dotted with <b>shopping</b> centers,{" "}
                    <b>restaurants</b>, eateries, & Bangalore's finest brew{" "}
                    <b>pubs</b>.
                </>
            ),
            // desc1: "The locality features some of Bangalore's leading hospitals and health centers, ensuring quick access to medical assistance. Additionally, the police station is conveniently located within 3 km from the community, ensuring the safety of all residents.",
            desc1: (
                <ul class="TranquilCourtList">
                    <li>
                        Natura walk <strong>4 Kms</strong>
                    </li>
                    <li>
                        Confident Oxygen Mall <strong>3.2 Kms</strong>
                    </li>
                    <li>
                        Upcoming Forum mall <strong>4 Kms</strong>
                    </li>
                    <li>
                        DMart <strong>6.2 Kms</strong>
                    </li>
                    <li>
                        Kidztopia <strong>6.5 Kms</strong>
                    </li>
                </ul>
            ),
            // button: "View on map",
            image: CampusImage,
        },
    ];

    return (
        <section
            className={`section ${inView ? "inView" : "outView"}
    `}
        >
            {SocialEntertainmentData.map((item, idx) => {
                return (
                    <Container key={idx} className="Campus">
                        <Row className="align-items-center flex-column-reverse flex-md-row">
                            <Col
                                xs={12}
                                md={6}
                                lg={6}
                                xl={7}
                                className="px-md-5"
                            >
                                <Heading
                                    variant="h1"
                                    classList="Campus__title"
                                    text={item.title}
                                />
                                <Desc
                                    classList="Campus__desc my-3 "
                                    text={item.desc}
                                />
                                <Desc
                                    classList="Campus__desc my-3 "
                                    text={item.desc1}
                                />
                                {/* <AnchorButtonIcon
                  // RECORD AUDIO BASED CHANGE
                  target="_blank"
                  href="https://www.google.com/maps/place/Soul+Tree+by+Modern+Spaaces/@12.86056,77.7643898,17z/data=!3m1!4b1!4m5!3m4!1s0x3bae738c023db8b3:0x1c0e1c2d5d103375!8m2!3d12.8605548!4d77.7665785"
                  // RECORD AUDIO BASED CHANGE
                  classList="Campus__btn my-3"
                  text={item.button}
                  icon={true}
                /> */}
                            </Col>
                            <Col
                                xs={12}
                                md={6}
                                lg={6}
                                xl={5}
                                className="pr-md-5"
                            >
                                <div className="imagePatch">
                                    <img
                                        src={item.image}
                                        className="Campus__image w-100 mb-4 mb-md-5"
                                        alt="Social & Medical Centers in Sarjapur"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                );
            })}
        </section>
    );
}
