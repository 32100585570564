// import floorPlan from "../../../images/Common/floor-plan.svg";
import masterPlan from "../../../images/Common/master-plan.svg";
// import projectBrochure from "../../../images/Common/project-brochure.svg";
import ICBrochure from "../../../documents/IvyCounty/Ivy-county-brochure.pdf";
import ICMasterPlan from "../../../documents/IvyCounty/Ivy-county-master-plan.pdf";

const projectBrochure = "/images/Common/project-brochure-banner.svg";

export const ProjectResourcesData = [
    // {
    //     key: "floorPlan",
    //     name: "Floor Plan",
    //     icon: floorPlan,
    //     IconAlt: "Floor Plan",
    //     resourceContent: {
    //         subject: "Ivy_county Floor Plan",
    //         fileName: "Ivy_county_Floor_Plan.pdf",
    //         filePath: GSFloorPlan,
    //     },
    // },
    {
        key: "masterPlan",
        name: "Master Plan",
        icon: masterPlan,
        IconAlt: "Master Plan",
        resourceContent: {
            subject: "Ivy_county Master Plan",
            fileName: "Ivy County - MasterPlan.pdf",
            filePath: ICMasterPlan,
        },
    },
    {
        key: "projectBrochure",
        name: "Project Brochure",
        icon: projectBrochure,
        IconAlt: "Project Brochure",
        resourceContent: {
            subject: "Ivy_county Brochure",
            fileName: "Ivy County - Brochure.pdf",
            filePath: ICBrochure,
        },
    },
];
