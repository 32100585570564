import { Container, Row, Col } from "react-bootstrap";
import { Desc, Heading } from "../../Global/Typography";
// import { Desc, Heading } from "../../Global/Typography";
// import TranquilCourtImage from "../../../images/SoulTree/TranquilCourt/TranquilCourtImage.png";
// import TranquilCourtImageLarge from "../../../images/SoulTree/TranquilCourt/TranquilCourtImageLarge.png";
import "./TranquilCourt.scss";
const patternVideo = "/images/IvyCounty/TranquilCourt/flower-block.mp4";
const TranquilCourtImageLarge =
    "/images/GreenStoreys/TranquilCourt/soccer-field.jpg";

export default function TranquilCourt({ inView }) {
    const TranquilCourtData = [
        {
            title: "Premium Amenities",
            desc: "Experience luxury at its finest in our 50,000 sq.ft. clubhouse, complete with world-class amenities. Unwind, entertain, and connect in style.",
            imageLarge: TranquilCourtImageLarge,

            patternVideo: patternVideo,
            // image: TranquilCourtImage,
            listItems: [
                "Seating Alcove",
                "Kid's Adventure Play Area",
                "Multi-Purpose Sports Court",
                "Forest Grove(Fruit Bearing Trees)",
                "View Deck",
                "Jogging Loop",
                "Tree of Life",
                "Flower Garden",
                "Senior's Pavilion",
                "Adventure/Nature Trail",
                "Overlooking Yoga Deck",
                "Community Garden",
            ],
        },
    ];

    return (
        <>
            <Container className="py-2 my-md-3">
                <Row className="justify-content-center">
                    <Col xs={12} md={6} lg={6} xl={12} className=" text-center">
                        <Heading
                            variant="h1"
                            classList=""
                            text="Exclusive Premium Amenities"
                        />
                    </Col>
                </Row>
            </Container>

            <section
                className={`section bg-white ${inView ? "inView" : "outView"}
    `}
            >
                {TranquilCourtData.map((item, idx) => {
                    return (
                        <Container key={idx} className="TranquilCourt">
                            <Row>
                                <Col
                                    xs={12}
                                    md={5}
                                    lg={5}
                                    xl={4}
                                    className="pr-md-5 pr-xl-4"
                                >
                                    {/* AS OF NOW */}
                                    {/* <Heading
                    variant="h1"
                    classList="TranquilCourt__title my-3"
                    text={item.title}
                  /> */}
                                    <Desc
                                        classList="TranquilCourt__desc my-3 "
                                        text={item.desc}
                                    />
                                    <ul className="TranquilCourtList">
                                        {item.listItems.map((i, j) => (
                                            <li key={j}>
                                                <Desc
                                                    classList="my-2"
                                                    text={i}
                                                />
                                            </li>
                                        ))}
                                    </ul>
                                </Col>
                            </Row>
                            <div className="TranquilCourtImage">
                                {/* <img
                src={item.image}
                className="TranquilCourt__image"
                alt="TranquilCourt"
              /> */}
                                {/* SEPARATE */}
                                <img
                                    src={item.imageLarge}
                                    className="TranquilCourt__imageLarge"
                                    alt="GreenStoreys a array of Outdoor Amenities  "
                                />

                                <video
                                    controls={false}
                                    className="TranquilCourt__imageSmall"
                                    loop={true}
                                    muted={true}
                                    autoPlay={true}
                                    playsInline={true}
                                >
                                    <source
                                        src={item.patternVideo}
                                        type="video/mp4"
                                    />
                                </video>
                            </div>
                        </Container>
                    );
                })}
            </section>
        </>
    );
}
