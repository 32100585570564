import React, { useState } from "react";
import { Row, Container, Tab, Tabs } from "react-bootstrap";
import { Heading } from "../../Global/Typography";

import "./GalleryTabs.scss";
import GSlider from "./GSlider";
import ModalVideo from "react-modal-video";
import VideoIconImage from "../../../images/SoulTree/VideoIconImage.svg";

export default function GalleryTabs({ inView }) {
    const [isOpen, setOpen] = useState(false);
    const GalleryTabsItems = [
        {
            title: "Gallery",
            image: <GSlider />,
            lists: [],
        },
        // { title: "Walkthrough", video: "KQclIldMmw0", placeholder: Gallery1 },
    ];

    return (
        <section
            className={`section ivy-county  ${inView ? "inView" : "outView"}
  `}
        >
            <Container className="GalleryTabs-Container GalleryTabs-Container--secondNoTab">
                <Row>
                    <Tabs
                        defaultActiveKey="Gallery"
                        className="GalleryTabs GalleryTabs--black container d-none"
                    >
                        {GalleryTabsItems.map((item, idx) => {
                            return (
                                <Tab
                                    key={idx}
                                    eventKey={item.title}
                                    title={
                                        <Heading
                                            classList="-title"
                                            variant="h1"
                                            text={item.title}
                                        />
                                    }
                                    className="event-tab"
                                >
                                    {item.image ? item.image : null}
                                    {item.video ? (
                                        <div className="TheHeartAndSoul__image-block ele">
                                            <ModalVideo
                                                channel="youtube"
                                                autoPlay
                                                isOpen={isOpen}
                                                // videoId="L61p2uyiMSo"
                                                videoId={item.video}
                                                onClose={() => setOpen(false)}
                                            />
                                            <img
                                                src={item.placholder}
                                                alt="Gallery"
                                                className="GalleryImageSlide img-responsive w-100"
                                            />
                                            <div
                                                className="videoIconImage videoIconImage--lg"
                                                onClick={() => setOpen(true)}
                                            >
                                                <img
                                                    src={VideoIconImage}
                                                    className="TheHeartAndSoul__videoIcon"
                                                    alt="TheHeartAndSoul"
                                                />
                                            </div>
                                        </div>
                                    ) : null}
                                </Tab>
                            );
                        })}
                    </Tabs>
                </Row>
            </Container>
        </section>
    );
}
