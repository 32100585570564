import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Desc, DescLabel } from "../../../Global/Typography";
import BannerNew from "../../BannerNew";
import { Helmet } from "react-helmet-async";
import "./index.scss";
import { scrollIntoView } from "seamless-scroll-polyfill";

import { Accordion, Card, Button } from "react-bootstrap";
import BlogData from "../../../../db/BlogNatureAtHome";
import ShareButton from "../../Share/ShareButton";

window.scrollToElement = function (id) {
    const el = document.querySelector(id);
    if (el) {
        window.scrollTo({
            behavior: "smooth",
            top: 200,
        });
    }
};

export default function Banner({ inView }) {
    const [activeKey, setActiveKey] = useState(null);

    const toggleAccordion = (key) => {
        setActiveKey(activeKey === key ? null : key);
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0"
                />
                <title>
                    Nature at Home: Balcony Garden Ideas for Urban Living
                </title>
                <meta
                    name="Keywords"
                    content="" //TODO
                />
                <meta
                    name="description"
                    content="Transform your urban balcony into a serene garden oasis with our inspiring decor ideas! Discover practical tips to create your perfect balcony retreat in this blog."
                ></meta>
                <link
                    rel="canonical"
                    href="https://modernspaaces.com/blogs/nature-at-home"
                />
            </Helmet>
            <section
                className={`section nature-at-home  ${
                    inView ? "inView" : "outView"
                }`}
            >
                <BannerNew
                    image={BlogData.image}
                    title={BlogData.title}
                    publishedOn={BlogData.publishedOn}
                    time={BlogData.time}
                    alt={BlogData.imageAlt}
                    position={"top"}
                />
                <Container className="blog-detail-container">
                    <Row className="justify-content-around">
                        <Col xs={12} md={11} lg={9} className="pb-4 pr-md-5">
                            <div className="blog-header margin">
                                {BlogData.title1 && (
                                    <p className="-descBlack font-benton-bold">
                                        {BlogData.title1}
                                    </p>
                                )}
                                {BlogData.description11 && (
                                    <Desc
                                        classList="font-benton-bold header"
                                        text={BlogData.description11}
                                    />
                                )}

                                {BlogData.title2 && (
                                    <p className="-descBlack font-benton-bold">
                                        {BlogData.title2}
                                    </p>
                                )}
                                {BlogData.description21 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description21}
                                    />
                                )}
                                {BlogData.title3 && (
                                    <p className="-descBlack font-benton-bold margin mb-3">
                                        {BlogData.title3}
                                    </p>
                                )}
                                {BlogData.description31 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description31}
                                    />
                                )}
                                {BlogData.list32 && (
                                    <ul className="blog-list-circle  header">
                                        {BlogData.list32.map((i1, idx1) => {
                                            return (
                                                <li key={idx1}>
                                                    <DescLabel
                                                        classList="my-1  font-benton-book header "
                                                        text={i1.description}
                                                        label={i1.label}
                                                    />
                                                </li>
                                            );
                                        })}
                                    </ul>
                                )}
                                {BlogData.title4 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title4}
                                    </p>
                                )}
                                {BlogData.list41 && (
                                    <ul className="blog-list-circle  header">
                                        {BlogData.list41.map((i1, idx1) => {
                                            return (
                                                <li key={idx1}>
                                                    <DescLabel
                                                        classList="my-1  font-benton-book header "
                                                        text={i1.description}
                                                        label={i1.label}
                                                    />
                                                </li>
                                            );
                                        })}
                                    </ul>
                                )}
                                {BlogData.description42 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description42}
                                    />
                                )}

                                {BlogData.title5 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title5}
                                    </p>
                                )}

                                {BlogData.description52 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description52}
                                    />
                                )}
                                {BlogData.title53 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title53}
                                    </p>
                                )}
                                {BlogData.description54 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description54}
                                    />
                                )}
                                <div className="imagepadding">
                                    <img
                                        src={BlogData.image53}
                                        alt={BlogData.imageAlt53}
                                        class="image margin"
                                    />
                                </div>
                                {BlogData.list55 && (
                                    <ul className="blog-list-circle  header">
                                        {BlogData.list55.map((i1, idx1) => {
                                            return (
                                                <li key={idx1}>
                                                    <DescLabel
                                                        classList="my-1  font-benton-book header "
                                                        text={i1.description}
                                                        label={i1.label}
                                                    />
                                                </li>
                                            );
                                        })}
                                    </ul>
                                )}
                                {BlogData.title56 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title56}
                                    </p>
                                )}
                                {BlogData.description57 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description57}
                                    />
                                )}
                                <div className="imagepadding">
                                    <img
                                        src={BlogData.image56}
                                        alt={BlogData.imageAlt56}
                                        class="image margin"
                                    />
                                </div>
                                {BlogData.list58 && (
                                    <ul className="blog-list-circle  header">
                                        {BlogData.list58.map((i1, idx1) => {
                                            return (
                                                <li key={idx1}>
                                                    <DescLabel
                                                        classList="my-1  font-benton-book header "
                                                        text={i1.description}
                                                        label={i1.label}
                                                    />
                                                </li>
                                            );
                                        })}
                                    </ul>
                                )}

                                {BlogData.list52 && (
                                    <ul className=" header">
                                        {BlogData.list52.map((i0, idx1) => {
                                            return (
                                                <li key={idx1}>
                                                    <DescLabel
                                                        classList="my-1 font-benton-book header "
                                                        text={i0.description}
                                                        label={i0.label}
                                                    />
                                                    {i0.list && (
                                                        <ul className="blog-list-circle header">
                                                            {i0.list.map(
                                                                (i1, idx1) => {
                                                                    return (
                                                                        <li
                                                                            key={
                                                                                idx1
                                                                            }
                                                                        >
                                                                            <p className="-descBlack mb-1 font-benton-book header">
                                                                                {
                                                                                    i1
                                                                                }
                                                                            </p>
                                                                        </li>
                                                                    );
                                                                }
                                                            )}
                                                        </ul>
                                                    )}
                                                    {i0.descriptionFooter && (
                                                        <Desc
                                                            classList="my-1 font-benton-book header "
                                                            text={
                                                                i0.descriptionFooter
                                                            }
                                                        />
                                                    )}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                )}
                                {BlogData.title6 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title6}
                                    </p>
                                )}
                                {BlogData.list61 && (
                                    <ul className="blog-list-circle  header">
                                        {BlogData.list61.map((i1, idx1) => {
                                            return (
                                                <li key={idx1}>
                                                    <DescLabel
                                                        classList="my-1  font-benton-book header "
                                                        text={i1.description}
                                                        label={i1.label}
                                                    />
                                                </li>
                                            );
                                        })}
                                    </ul>
                                )}

                                {BlogData.title7 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title7}
                                    </p>
                                )}
                                {BlogData.list71 && (
                                    <ul className="blog-list-circle  header">
                                        {BlogData.list71.map((i1, idx1) => {
                                            return (
                                                <li key={idx1}>
                                                    <DescLabel
                                                        classList="my-1  font-benton-book header "
                                                        text={i1.description}
                                                        label={i1.label}
                                                    />
                                                </li>
                                            );
                                        })}
                                    </ul>
                                )}

                                {BlogData.description72 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description72}
                                    />
                                )}

                                {BlogData.title8 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title8}
                                    </p>
                                )}
                                {BlogData.description81 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description81}
                                    />
                                )}

                                {BlogData.list82 && (
                                    <ul className=" header">
                                        {BlogData.list82.map((i0, idx1) => {
                                            return (
                                                <li key={idx1}>
                                                    <DescLabel
                                                        classList="my-1 font-benton-book header "
                                                        text={i0.description}
                                                        label={i0.label}
                                                    />
                                                    {i0.list && (
                                                        <ul className="blog-list-circle header">
                                                            {i0.list.map(
                                                                (i1, idx1) => {
                                                                    return (
                                                                        <li
                                                                            key={
                                                                                idx1
                                                                            }
                                                                        >
                                                                            <p className="-descBlack mb-1 font-benton-book header">
                                                                                {
                                                                                    i1
                                                                                }
                                                                            </p>
                                                                        </li>
                                                                    );
                                                                }
                                                            )}
                                                        </ul>
                                                    )}
                                                    {i0.descriptionFooter && (
                                                        <Desc
                                                            classList="my-1 font-benton-book header "
                                                            text={
                                                                i0.descriptionFooter
                                                            }
                                                        />
                                                    )}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                )}

                                {BlogData.title9 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title9}
                                    </p>
                                )}
                                {BlogData.description91 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description91}
                                    />
                                )}
                            </div>
                        </Col>
                    </Row>
                    <Row className="simpleShadow my-3 py-3 justify-content-around">
                        <Col
                            xs={12}
                            md={11}
                            lg={9}
                            className="pb-4 pr-md-5 p-2"
                        >
                            <Row>
                                <div
                                    className="blog-content px-3"
                                    style={{
                                        width: "-webkit-fill-available",
                                    }}
                                >
                                    {BlogData.Faq && (
                                        <p className="-descBlack font-benton-bold  margin header2">
                                            {BlogData.Faq}
                                        </p>
                                    )}
                                    {BlogData.faqs &&
                                        BlogData.faqs.map((faq, faqIdx) => (
                                            <Accordion
                                                key={faqIdx}
                                                defaultActiveKey={faqIdx.toString()}
                                                activeKey={activeKey}
                                            >
                                                <Card
                                                    key={faqIdx}
                                                    className="my-2 p-2 font-benton-bold"
                                                    style={{
                                                        width: "fit",
                                                        border: "none",
                                                        backgroundColor:
                                                            "rgba(0, 0, 0, 0.02)",
                                                        borderRadius: "0.5rem",
                                                    }}
                                                >
                                                    <Accordion.Toggle
                                                        as={Card.Header}
                                                        eventKey={`${faqIdx}`}
                                                        className="font-benton-bold faq"
                                                        style={{
                                                            border: "none",
                                                            background: "none",
                                                        }}
                                                        onClick={() =>
                                                            toggleAccordion(
                                                                faqIdx.toString()
                                                            )
                                                        }
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                                justifyContent:
                                                                    "space-between",
                                                                cursor: "pointer",
                                                                borderBottom:
                                                                    "none",
                                                            }}
                                                        >
                                                            {faq.label}
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="20"
                                                                height="21"
                                                                viewBox="0 0 20 21"
                                                                fill="none"
                                                                style={{
                                                                    transform:
                                                                        activeKey ===
                                                                        faqIdx.toString()
                                                                            ? "rotate(180deg)"
                                                                            : "rotate(0deg)",
                                                                }}
                                                            >
                                                                <path
                                                                    d="M16.5984 7.96094L11.1651 13.3943C10.5234 14.0359 9.47344 14.0359 8.83177 13.3943L3.39844 7.96094"
                                                                    stroke="black"
                                                                    strokeOpacity="0.32"
                                                                    strokeWidth="2"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                            </svg>
                                                        </div>
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse
                                                        eventKey={`${faqIdx}`}
                                                    >
                                                        <Card.Body className="font-benton-book header p-3">
                                                            <Card.Body className="font-benton-book header p-3">
                                                                {
                                                                    faq.description
                                                                }
                                                            </Card.Body>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion>
                                        ))}
                                </div>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="simpleShadow my-3 py-3 justify-content-around">
                        <Col
                            xs={12}
                            md={11}
                            lg={9}
                            className="pb-4 pr-md-5 p-2"
                        >
                            <div
                                className="shareLinkBlock1 d-flex flex-row p-4 "
                                style={{
                                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                                    gap: "1rem",
                                    borderRadius: "1rem",
                                }}
                            >
                                <ShareButton shareUrl="https://modernspaaces.com/blogs/nature-at-home" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}
